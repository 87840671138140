import { useState } from "react";
import Item from "./Item";

export default function PackagingList({
  items,
  onDeleteItem,
  onToggleItem,
  onClearList,
}) {
  const [sortBy, setSortBy] = useState("input");
  let sortedItems = items;
  if (sortBy === "input") sortedItems = items;
  if (sortBy === "description")
    sortedItems = items
      .slice()
      .sort((a, b) => a.description.localeCompare(b.description));
  if (sortBy === "packed")
    sortedItems = items
      .slice()
      .sort((a, b) => Number(a.packed) - Number(b.packed));
  return (
    <div className='list overflow-scroll' id='non-scroll'>
      <ul>
        {sortedItems.map((item) => (
          <Item
            item={item}
            key={item.id}
            onDeleteItem={onDeleteItem}
            onToggleItem={onToggleItem}
          />
        ))}
      </ul>
      <div className='action'>
        <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
          <option value='input'>order by input</option>
          <option value='description'>order by description</option>
          <option value='packed'>order by packed</option>
        </select>
        <button
          className='lg:py-5 lg:px-12 lg:text-3xl text-xl py-4 px-9'
          onClick={onClearList}
        >
          clear list
        </button>
      </div>
    </div>
  );
}

import { useState } from "react";
import Stats from "./Stats";
import Logo from "./Logo";
import Form from "./Form";
import PackagingList from "./PackagingList";

export default function App() {
  const [items, setItems] = useState([]);
  //Adding Items to list
  function handleAddItems(item) {
    setItems((items) => [...items, item]);
  }
  //Deleting items out of list
  function handleDeleteItem(id) {
    setItems((items) => items.filter((item) => item.id !== id));
  }
  //checking items out of list
  function handleToggleItem(id) {
    setItems((items) =>
      items.map((item) =>
        item.id === id ? { ...item, packed: !item.packed } : item,
      ),
    );
  }
  //clear the list
  function handleClearItems() {
    const confirmed = window.confirm(
      "Are you sure you want to delete all items?",
    );
    if (confirmed) setItems([]);
  }
  return (
    <div className='w-screen h-screen app'>
      <Logo />
      <Form onAddItems={handleAddItems} />
      <PackagingList
        items={items}
        onDeleteItem={handleDeleteItem}
        onToggleItem={handleToggleItem}
        onClearList={handleClearItems}
      />
      <Stats items={items} />
    </div>
  );
}

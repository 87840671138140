export default function Stats({ items }) {
  if (!items.length)
    return (
      <footer className='stats text-2xl lg:text-4xl'>
        <em>Start adding items to your packing list🚀</em>
      </footer>
    );
  const numItems = items.length;
  const numPacked = items.filter((item) => item.packed).length;
  const percentage = Math.round((numPacked / numItems) * 100);
  return (
    <footer className='stats text-xl lg:text-4xl'>
      <em>
        {percentage === 100
          ? `You got everything! ready to go ✈️`
          : `You have ${numItems} items in your list, and you already packed
        ${numPacked} (${percentage}%)`}
      </em>
    </footer>
  );
}

import { useState } from "react";

export default function Form({ onAddItems }) {
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(1);

  function handleSubmit(e) {
    e.preventDefault();
    if (!description) return;
    const newItem = { description, quantity, packed: false, id: Date.now() };
    onAddItems(newItem);
    setDescription("");
    setQuantity(1);
  }
  return (
    <form
      className='add-form flex-col flex lg:flex-row justify-center items-center gap-5 lg:gap-3 py-4 lg:py-11'
      onSubmit={handleSubmit}
    >
      <h3 className='text-3xl lg:text-5xl '>
        What do you need for your 😍 trip?
      </h3>
      <input
        className='lg:py-5 lg:px-12 lg:text-3xl text-xl py-4 px-9'
        placeholder='Items...'
        type='text'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <select
        className='lg:py-5 lg:px-12 lg:text-3xl text-xl py-4 px-9'
        value={quantity}
        onChange={(e) => setQuantity(Number(e.target.value))}
      >
        {Array.from({ length: 20 }, (_, i) => i + 1).map((num) => (
          <option value={num} key={num}>
            {num}
          </option>
        ))}
      </select>

      <button className='lg:py-5 lg:px-12 lg:text-3xl text-xl py-4 px-9'>
        Add
      </button>
    </form>
  );
}
